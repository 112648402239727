import React from 'react';

import useStateWithLocalStorage from '../hooks/hook.useStateWithLocalStorage';

const BayListOptions = () => {
    const [session] = useStateWithLocalStorage('session');
    let options = [];
    let { bay_count } = session;
    if (bay_count !== undefined) {
        for (let i = 1; i <= bay_count; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
    }
    return options;
}

export default BayListOptions;
