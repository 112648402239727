import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import useStateWithLocalStorage from "../hooks/hook.useStateWithLocalStorage";

const PrivateRoute = ({component: Component, ...rest}) => {
    const [session, setSession] = useStateWithLocalStorage('session');
    
    const isLoggedIn = () => {
        return session.portal_password;
    }
    
    return (
        <Route {...rest} render={(props) => {
            const combineProps = { ...props, session, setSession }

            return (
                isLoggedIn() ?
                    <Component {...combineProps} />
                    : <Redirect to="/" />
            )}
        }  />
    );
}

export default PrivateRoute;
