
export const getTwelveDigitRFID = (tag) => {
    var tag_exp = [], // Tag expression
        chr_bin = [], // 4 Binary Bits (each character in the Tag ID)
        tag_bin = [], // Tag Binary (1 Byte)
        new_tag = ''; // Tag Conversion result.

    tag.toUpperCase();

    // convert each character in the Tag ID to 4 binary bits
    for (let i = 0; i < tag.length; i++) {
        // assign the tag expression length to capture 4 binary bits on each tag character
        tag_exp[i] = new Array(8);
        // convert the single character to its bit value
        chr_bin[i] = parseInt(parseInt((tag[i] + '').replace(/[^a-f0-9]/gi, 0), 16), 10).toString(2);
        // pad the binary bit integer conversion to 4 character string with leading 0's
        chr_bin[i] = String(chr_bin[i]).padStart(4, '0');
    }

    // convert each 4 bit Tag expression to 8 bits (1 Byte)
    for (let i = 0; i < tag.length; i++) {
        // Join 4 bit binary segments (second not required) to equal 1 Byte
        if (i % 2 == 0) {
            tag_exp[i] = (chr_bin[i] + chr_bin[i + 1]).split('');
        } else {
            if (i > 2) {
                for (let k = 0; k < 8; k++) {
                    tag_exp[i][k] = (parseInt(tag_exp[i - 1][k]) == 0) ? ((tag_exp[(i == 3) ? i - 3 : i - 2][k]) == 1 ? 1 : 0) : ((tag_exp[(i == 3) ? i - 3 : i - 2][k]) == 0 ? 1 : 0);
                }
            }
        }
        tag_bin[i] = tag_exp[i].join('');
    }

    // Create the last two characters from the last generated Byte
    for (let s = 0; s <= 4; s += 4) {
        chr_bin[parseInt(chr_bin.length)] = tag_bin[parseInt(tag_bin.length) - 1].substr(s, 4);
    }

    // Join the last 2 characters to the new Tag ID
    for (let x = 0; x < 12; x++) {
        new_tag += parseInt(chr_bin[x], 2) >= 10 ? ((Number(parseInt(chr_bin[x], 2).toString()).toString(16))).slice(-2).toUpperCase() : parseInt(chr_bin[x], 2).toString();
    }

    return new_tag;
}