import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useMakeRequest from "../hooks/hook.useMakeRequest";
import useInterval from "../hooks/hook.useInterval";
import {Reset} from "../helpers/session";

const Header = ({
    title,
    withBackButton = false,
    backBtnUrl = false,
    transparent = false,
    withLogout = true ,
    heading = "",
    description = "",
    image = "",
    step =  "",
    timeoutMinutesLength = 5,
    session,
    setSession
}) => {
    const history = useHistory();
    const [pin, setPin] = useState('');
    const [submit, setSubmit] = useState(false);
    const [bayStatusSubmit, setBayStatusSubmit] = useState(false);
    const [delay, setDelay] = useState(null);
    const [isBayDiff, setIsBayDiff] = useState(false);
    const [timeoutDelay, setTimeoutDelay] = useState(null);
    const [timeout, setTimeout] = useState(0);

    useInterval(() => {
        setBayStatusSubmit(true);
    }, delay);

    useInterval(() => {
        setTimeout(timeout + 1);
    }, timeoutDelay);

    const [pinRequest] = useMakeRequest({
        path: '/verifyPin',
        data: {
            portal_pin: pin,
        },
        submit
    });

    const [bayStatusRequest] = useMakeRequest({
        path: '/returnDockBaysStatus',
        data: {
            include_additional: 0,
        },
        submit: bayStatusSubmit
    });

    const logout = async () => {
        await setSession({});
        history.push('/login');
    }

    const verifyPin = async () => {
        const pinPrompt = prompt('Enter Staff Pin Number');
        await setPin(pinPrompt);
        setSubmit(true);
    }

    // handle pin request response
    useEffect(() =>  {
        if (pinRequest.success && pinRequest.response) {
            if (pinRequest.response.success) {
                history.push('/bay-status')
            } else {
                const errors = Object.values(pinRequest.response.errors);
                const first = errors[0] !== undefined ? errors[0] : 'There was a problem';
                toast.error(first);
            }
        }

        setSubmit(false);
    }, [pinRequest]);

    // handle bay status response
    useEffect(() =>  {
        if (bayStatusRequest.success && bayStatusRequest.response && bayStatusRequest.response.data) {
            const { data } = bayStatusRequest.response;
            const bays = session.bays !== undefined ? session.bays : false;
            if (!bays || bays.length === 0) {
                // store a version of the bays in local storage if we do not have one yet
                setSession({...session, bays: Object.values(data) });
            } else {
                // compare the recent bays with the original stored version
                const newBays = Object.values(data);
                for (const [key, bay] of Object.entries(bays)) {
                    if (newBays.hasOwnProperty(key) && newBays[key].reservation_status !== bay.reservation_status) {
                        // if there's a diff, set it and exit
                        // bays will be reset when they visit the bay-status page
                        setIsBayDiff(true);
                        break;
                    }
                    setIsBayDiff(false);
                }
            }
        }
        setBayStatusSubmit(false);
    }, [bayStatusRequest]);

    // timeout
    useEffect ( () => {

        if (timeout === timeoutMinutesLength) {
            async function asyncReset() {
                await Reset({ session, setSession });
            }
            // reset session and reload page
            asyncReset();
            setTimeout(0);
            history.push('/dashboard')
        }
    }, [timeout]);

    // on page load
    useEffect (() => {
        if (history.location.pathname === '/dashboard') {
            setBayStatusSubmit(true);
            setDelay(3000);
        }
        if (history.location.pathname !== '/login') {
            setTimeoutDelay(60000);
        }
    }, []);

    return (
        <>
            <div className={transparent ? 'header header--transparent' : 'header'}>
                {withBackButton && (
                    <span onClick={backBtnUrl ? () => history.push(backBtnUrl) : history.goBack} className="header__back">
                        <i className="far fa-chevron-left"></i>
                    </span>
                )}
                {title && (<div className="header__title">{title}</div>)}
                {!title && !transparent && (<div className="header__title">Brompton Bike Hire Portal</div>)}
                {history.location.pathname === '/dashboard' && (
                    <span className="header__bell" onClick={() => verifyPin()}>
                        <i className="far fa-bell"></i>
                        {isBayDiff && (<span className="header__alert"></span>)}
                    </span>)
                }
                {withLogout && (
                    <>
                        <span className="header__home" onClick={() => history.push('/dashboard')}>
                            <i className="far fa-home"></i>
                        </span>
                        <span className="header__logout" onClick={() => logout()}>
                            <i className="far fa-sign-out-alt"></i>
                        </span>
                    </>
                )}
            </div>

            {/* Content Below This Line */}
            {image && (
                <div className="header__background">
                    {image && (<img className="header__logo" src={image} alt="Brompton Bike Hire Logo" />)}
                    {step && (
                        <div className="header__step">
                            {step}<span className="header__step--small">/3</span>
                        </div>
                    )}
                </div>
            )}
            {heading && description && (
                <div className="header__intro">
                    {heading && (<div className="header__heading">{heading}</div>)}
                    {description && (<div className="header__description">{description}</div>)}
                </div>
            )}
        </>

    );
}

export default Header;
