import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useMakeRequest from "../hooks/hook.useMakeRequest";
import Header from "../components/component.header";
import { getTwelveDigitRFID } from "../helpers/rfid";
import Loader from "../components/component.loader";
import logo from "../assets/images/brompton.png";


const Rfid = ({ session, setSession }) => {
    const history = useHistory();
    const [rfidValue, setRfidValue] = useState('');
    const [convertedRfidValue, setConvertedRfidValue] = useState('');
    const [submit, setSubmit] = useState(false);

    const [request] = useMakeRequest({
        path: '/verifyRFID',
        data: {
            bay_number: session.bay_number,
            rfid: convertedRfidValue,
        },
        submit
    });

    const checkLengthAndSubmit = async (value) => {
        const { loading } = request;
        if (!loading && (value.length === 10 || value.length === 12)) {
            const newRfid = value.length === 10 ? getTwelveDigitRFID(value) : value.toUpperCase();
            await setConvertedRfidValue(newRfid);
            setSubmit(true);
        } else {
            setRfidValue(value.toUpperCase());
        }
    }

    const isLoading = () => {
        const { loading } = request;
        return loading;
    }

    // check for API response statuses
    useEffect(() => {
        if (request.success && request.response && request.response.success) {
            const { type } = request.response.data;
            history.push('/success', { type });
        } else if (request.success && request.response && !request.response.success) {
            toast.error(request.response.errors[0]);
            setRfidValue('');
        }
        setSubmit(false);
    }, [request]);

    return (
        <>
            <Header withLogout={false}
                    withBackButton
                    image={logo}
                    heading="Staff member final step"
                    description="Please scan the bikes RFID tag"
                    session={session}
                    setSession={setSession}
            />
            <div className="form rfid">
                <Loader isLoading={isLoading()} />
                <div className="form__element form__element--no-border">
                    <div className="rfid__details">
                        {session.bay_number && <div className="rfid__bay">Bay: <span className="rfid__bay-number">{session.bay_number}</span></div>}
                        {session.bays != undefined && session.bays[parseInt(session.bay_number) - 1] && session.bays[parseInt(session.bay_number) - 1].bike_name !== "" && (
                            <div className="rfid__bay">Bike: <span className="rfid__bay-number">{session.bays[parseInt(session.bay_number) - 1].bike_name}</span></div>
                        )}
                    </div>
                    <input className="rfid__input" type="text"
                           name="rfid"
                           value={rfidValue}
                           autoFocus={true}
                           onChange={(event) => checkLengthAndSubmit(event.target.value)}/>
                </div>
            </div>
        </>

    );
}

export default Rfid;
