import React  from 'react';
import { useHistory } from "react-router-dom";

import Header from "../components/component.header";
import step3 from "../assets/images/step-3.svg";

const Staff = ({ session, setSession }) => {
    const history = useHistory();

    const notifyStaff = async () => {
        await setSession({...session, staff: true });
        history.push('/dashboard');
    }

    return (
        <>
            <Header withLogout={false}
                    withBackButton
                    heading="Notify in store staff member"
                    description="Please ask a member of staff to scan the bike for you to complete your bike hire"
                    image={step3}
                    step="3"
                    session={session}
                    setSession={setSession}
            />
            <div className="staff__options">
                <button className="btn btn-primary" onClick={() => notifyStaff()}>Done</button>
            </div>
        </>

    );
}

export default Staff;
