import React  from 'react';
import { useHistory } from "react-router-dom";

import Header from "../components/component.header";
import { Reset } from "../helpers/session";

const Success = ({ location, session, setSession }) => {
    const history = useHistory();

    const returnToDashboard = async () => {
        await Reset({ session, setSession });
        history.push('/dashboard');
    }

    return (
        <>
            <Header
                withLogout={false}
                session={session}
                setSession={setSession}
            />
            <div className="success">
                <div className="success__icon">
                    <i className="far fa-check-circle"></i>
                </div>
                <div className="success__heading">Success</div>
                {location && location.state && location.state.type === 'hire' && (
                    <>
                        <div className="success__description">You may now hand the bike over to the customer.</div>
                        <div className="success__description">Ask the customer to check that the bike hire is active in their app.</div>
                    </>
                )}
                {location && location.state && location.state.type === 'return' && (
                    <>
                        <div className="success__description">You may now take the bike from the customer.</div>
                        <div className="success__description">Ask the customer to check that the bike hire is complete in their app.</div>
                    </>
                )}
                <div className="success__button">
                    <button className="btn" onClick={() => returnToDashboard()}>Return to dashboard</button>
                </div>
            </div>
        </>

    );
}

export default Success;
