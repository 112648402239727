import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/component.header";
import step1 from "../assets/images/step-1.svg";
import BayListOptions from "../components/component.bay-list-options";

const Bay = ({ session, setSession }) => {
    const history = useHistory();
    const [bayNumber, setBayNumber] = useState('');

    const storeBayNumber = async () => {
        if (!bayNumber || bayNumber === "0") {
            toast.info('Please select a bay number (e.g. 5)');
        } else {
            await setSession({...session, bay_number: bayNumber });
            history.push('/dashboard');
        }
    }

    return (
        <>
            <Header withLogout={false}
                    heading="Select a bay number"
                    description="Please select the bay number of your reservation"
                    image={step1}
                    step="1"
                    session={session}
                    setSession={setSession}
                    withBackButton
            />
            <div className="form bay">
                <div className="form__element">
                    <select
                        name="bay"
                        onChange={(event) => setBayNumber(event.target.value)}>
                        <option key="enter-a-value" value="0">Bay number</option>
                        <BayListOptions />
                    </select>
                </div>
                <button className="btn" onClick={() => storeBayNumber()}>Submit</button>
            </div>
        </>

    );
}

export default Bay;
