import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { geolocated, geoPropTypes } from "react-geolocated";

import useStateWithLocalStorage from "../hooks/hook.useStateWithLocalStorage";
import useMakeRequest from "../hooks/hook.useMakeRequest";
import Header from "../components/component.header";
import Loader from "../components/component.loader";
import logo from "../assets/images/brompton.png";

const Login = ({ isGeolocationEnabled, isGeolocationAvailable, coords }) => {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [session, setSession] = useStateWithLocalStorage('session');
    const [submit, setSubmit] = useState(false);
    const [request] = useMakeRequest({
        path: '/login',
        data: {
            portal_password: password,
            latitude: coords !== null && coords.latitude !== undefined ? coords.latitude  : '',
            longitude:  coords !== null && coords.longitude !== undefined ? coords.longitude  : '',
        },
        submit
    });

    const login = async () => {
        if (!isGeolocationAvailable) {
            return toast.error('Geolocation is not available in this browser');
        }

        if (!isGeolocationEnabled) {
            return toast.error('Geolocation is not enabled, please enable and refresh this page');
        }

        if (coords === null) {
            return toast.info('Your location is loading, please wait');
        }

        // valid input
        if (!password) {
            return toast.info('Please enter a password');
        } else {
            // trigger the request
            await setSubmit(true);
        }
    }

    const enterLogin = async (e) => {
        e.preventDefault();
        return login();
    }

    const isLoading = () => {
        const { loading } = request;
        return loading;
    }

    useEffect(() =>  {
        const saveAndRedirect = async () => {
            const { data } = request.response;
            const { latitude, longitude } = coords;
            await setSession({...data, ...{ latitude, longitude }});
            history.push('/dashboard');
        }

        const showError = async () => {
            setPassword('');
            toast.error(request.response.errors[0]);
        }

        if (request.success) {
            if (request.response && request.response.success) {
                saveAndRedirect();
            } else {
               showError();
            }
        }
        setSubmit(false);
    }, [request]);

    // reset the session on page load
    useEffect(() =>  {
        return setSession({});
    }, []);

    return (
        <>
            <Header transparent withLogout={false} />
            <div className="login__background">
                <img className="login__logo" src={logo} alt="Brompton Bike Hire Logo" />
            </div>
            <form className="form login" onSubmit={(e) => enterLogin(e)}>
                <Loader isLoading={isLoading()} />
                <h1 className="login__title">Staff Login</h1>
                <p className="login__text login__text--mb-4">Please enter your portal password</p>
                <div className="form__element">
                    <input type={showPassword ? 'text' : 'password'}
                           name="password"
                           placeholder="Password"
                           value={password}
                           onChange={(event) => setPassword(event.target.value)}/>
                    <span className="form__toggle-password" onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</span>
                </div>
                <p className="login__text login__text--small login__text--left">Forgot Password? Please contact Brompton Bike Hire customer services on 0203 474 0700</p>
                <button type="button"  className="btn" onClick={() => login()}>Log in</button>
            </form>
        </>

    );
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(Login);
