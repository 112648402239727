import React from 'react';
import { useHistory } from "react-router-dom";

import Header from "../components/component.header";
import logo from "../assets/images/brompton.png";
import ListItem from "../components/component.list-item";
import { Reset  } from '../helpers/session';

const Dashboard = ({ session, setSession }) => {
    const history = useHistory();

    const isBayComplete = () => {
        const { bay_number } = session;
        return bay_number !== undefined;
    }

    const isScanComplete = () => {
        const { qrcode_status } = session;
        return qrcode_status !== undefined && qrcode_status === true;
    }

    const isStaffComplete = () => {
        const { staff } = session;
        return staff !== undefined && staff === true;
    }

    const isComplete = () => {
        return isBayComplete() && isScanComplete() && isStaffComplete();
    }

    return (
        <>
            <Header
                withLogout={false}
                heading="Hire a bike in 3 easy steps"
                description="You'll need your phone and be registered on the Brompton Bike Hire app, available on Android and iOS. This store will feature as a virtual dock. Simply select an available bike from this store in your app to begin."
                image={logo}
                session={session}
                setSession={setSession}
            />
            <div className="dashboard__list">
                <ListItem title={session.bay_number ? "Selected bay number: " : "Select a bay number"} bayNumber={session.bay_number} showChecked={isBayComplete()} showArrow={!isBayComplete()} onClick={() => isBayComplete() ? null : history.push('/bay')} />
                <ListItem title="Scan QR Code" showChecked={isScanComplete()} showArrow={!isScanComplete()} onClick={() => !isBayComplete() || isScanComplete() ? null : history.push('/qr-code')} />
                <ListItem title="Notify in store staff member" showChecked={isStaffComplete()} showArrow={!isStaffComplete()} onClick={() => !isBayComplete() || !isScanComplete() || isStaffComplete() ? null : history.push('/staff')} />
            </div>
            <div className="dashboard__options">
                {isComplete() && (<button className="btn" onClick={() => history.push('/rfid')}>Staff Member to Proceed</button>)}
                <button className="btn" onClick={() => Reset({ session, setSession })}>Start over?</button>
            </div>
        </>

    );
}

export default Dashboard;
