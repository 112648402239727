import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ApiUrl } from '../constants/Config';
import useStateWithLocalStorage from "./hook.useStateWithLocalStorage";

const useMakeRequest = ({ path, data, submit = false, method = 'POST' }) => {
    const history = useHistory();
    const [session, setSession] = useStateWithLocalStorage('session');

    const defaults = {
        success: false,
        loading: false,
        response : false,
    }
    const [response, setResponse] = useState(defaults);
    
    async function fetchUrl() {

        const defaults = {
            portal_password: session.portal_password !== undefined ? session.portal_password : false,
            latitude: session.latitude !== undefined ? session.latitude  : '',
            longitude:  session.longitude !== undefined ? session.longitude  : '',
        }

        for (const [key, value] of Object.entries(defaults)) {
            if (!data.hasOwnProperty(key)) {
                data[key] = value;
            }
        }

        // setup the format data
        const formData = new FormData();
        Object.keys(data).map(key => formData.append(key, data[key]));
        
        const url = ApiUrl;
        
        // submit the request
        const response = await fetch(url + path, {
            method: method,
            body: formData
        });

        await response.json().then((response) => {
                if (isUnauthorized(response)) {
                    return redirectToLogin(response);
                }
                // set the response
                setResponse({
                    success: true,
                    loading: false,
                    response: response
                })
            })
            .catch((error) => {
                toast.error(error.toString());
                // return the error
                setResponse({
                    success: false,
                    loading: false,
                    response: error
                });
            });
    }


    const redirectToLogin = (response) => {
        setSession({});
        if (response && response.errors.length > 0) {
            toast.error(response.errors[0]);
        }
        history.push('/login');
    }

    const isUnauthorized = (response) => {
        return (response && response.errors.length > 0 && response.errors[0] === 'Attempting to access resource while not logged in');
    }

    useEffect(() => {
        // trigger the request
        if (submit) {
            setResponse({...defaults, ...{ loading: true }});
            fetchUrl();
        }

    }, [submit]);
    
    return [response];
}

export default useMakeRequest;
